html,
body {
  font-family: "azarmehr", "Roboto", sans-serif;
}

[lang="fa"] {
  --line-height: 1.75em;
  line-height: 1.75;
}
[lang="en"] {
  --line-height: 1.5em;
  line-height: 1.5;
}

[lang="en"] .H1,
[lang="en"] .H2,
[lang="en"] .H3,
[lang="en"] .H4,
[lang="en"] .H5,
[lang="en"] .H6,
[lang="en"] .large-title,
[lang="en"] .font-display {
  font-family: rubik, "Roboto", sans-serif;
}

[lang="fa"] .H1,
[lang="fa"] .H2,
[lang="fa"] .H3,
[lang="fa"] .H4,
[lang="fa"] .H5,
[lang="fa"] .H6,
[lang="fa"] .large-title,
[lang="fa"] .font-display {
  font-family: "azarmehr", "Roboto", sans-serif;
}

:root {
  outline-color: #f76808;
}

img {
  font-style: italic;
  color: var(--rx-sand11) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* SHADOWS =============================  */
:root {
  /* --shadow-1: 0px 2px 10px -4px  oklch(95% 0 256);
  --shadow-2: 0px 8px 16px -8px  oklch(94% 0 256);
  --shadow-3: 0px 12px 40px -6px  oklch(92% 0 256);
  --shadow-4: 0px 20px 50px -6px  oklch(90% 0 256);
  --shadow-5: 0px 30px 80px -2px oklch(88% 0 256);

  --shadow-tinted-1: 0px 2px 10px -4px  oklch(95% 0.022 56.09);
  --shadow-tinted-2: 0px 8px 16px -8px  oklch(93% 0.022 56.09);
  --shadow-tinted-3: 0px 12px 20px -6px  oklch(91% 0.022 56.09);
  --shadow-tinted-4: 0px 20px 50px -6px  oklch(89% 0.022 56.09);
  --shadow-tinted-5: 0px 30px 80px -2px oklch(87% 0.022 56.09); */

  --blured: 1;
  --shadow-1: 0px 3px 5px -2px oklch(89% 0 256);
  --shadow-2: 0px 14px 15px -8px oklch(98% 0 256);
  --shadow-3: 0px 22px 35px -10px oklch(85% 0 256);
  --shadow-4: 0px 36px 70px -18px oklch(84% 0 256);
  --shadow-5: 0px 54px 90px -24px oklch(83% 0 256);

  --blured: 1;
  --shadow-tinted-1: 0px 3px 5px -2px oklch(89% 0.022 56.09);
  --shadow-tinted-2: 0px 14px 15px -8px oklch(88% 0.022 56.09);
  --shadow-tinted-3: 0px 22px 35px -10px oklch(85% 0.022 56.09);
  --shadow-tinted-4: 0px 36px 70px -8px oklch(84% 0.022 56.09);
  --shadow-tinted-5: 0px 54px 90px -24px oklch(83% 0.022 56.09);
}

/* END OF SHADOWS */

input[type="text"] {
  min-width: 0 !important;
}

html {
  min-height: 100%;
}

body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

/* Side Menu Styles */

:root {
  --sidebar-width: 12rem;
  --side-menu-transition-time: 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  :root {
    --side-menu-transition-time: 1ms;
  }
}

.side-menu-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "aside main";
}

.aside {
  grid-area: aside;
}

.main {
  grid-area: main;
}

@media (max-width: 640px) {
  .aside {
    grid-area: main;
  }
}

.aside {
  --easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
  --side-menu-transition-time: 0.6s;
}

/* .w-aside {
  width: var(--sidebar-width);
} */

/* .max-w-main {
  max-width: calc(100vw - var(--sidebar-width));
} */

@media (min-width: 641px) {
  .aside {
    position: sticky;
    inset-block-start: 0;
    max-height: 100vh;
    overflow: hidden auto;
  }
}

@media (max-width: 640px) {
  .aside {
    position: fixed;
    inset-block: 0;
    inset-inline-start: 0;
    max-height: 100vh;
    overflow: hidden auto;
    overscroll-behavior: contain;

    visibility: hidden; /* not keyboard accessible when closed */
    transform: translateX(-110vw);
    will-change: transform;
    transition: transform var(--side-menu-transition-time) var(--easeOutExpo),
      visibility 0s linear var(--side-menu-transition-time);
  }

  /* to adjust for right to left layout */
  [lang="fa"] .aside {
    transform: translateX(110vw);
  }
  .aside.drawer-open {
    visibility: visible;
    transform: translateX(0);
    transition: transform var(--side-menu-transition-time) var(--easeOutExpo);
  }
}

/* End of Side menu styles */

label,
input {
  display: block;
}

p a,
.in-text-link {
  text-decoration-line: underline;
  text-underline-offset: 0.15em;
}

a {
  text-underline-offset: 0.15em;
}

.rating {
  --dir: right;
  --fill: gold;
  --fillbg: rgba(100, 100, 100, 0.15);
  --star: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 17.25l-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609 7.172 0.609-5.438 4.734 1.641 7.031z"/></svg>');
  --stars: 5;
  --starsize: 3rem;
  --symbol: var(--star);
  --value: 0;
  --w: calc(var(--stars) * var(--starsize));
  --x: calc(100% * (var(--value) / var(--stars)));
  block-size: var(--starsize);
  inline-size: var(--w);
  position: relative;
  touch-action: manipulation;
  -webkit-appearance: none;
}

.rating::-moz-range-track {
  background: linear-gradient(to var(--dir), var(--fill) 0 var(--x), var(--fillbg) 0 var(--x));
  block-size: 100%;
  mask: repeat left center/var(--starsize) var(--symbol);
}
.rating::-webkit-slider-runnable-track {
  background: linear-gradient(to var(--dir), var(--fill) 0 var(--x), var(--fillbg) 0 var(--x));
  block-size: 100%;
  mask: repeat left center/var(--starsize) var(--symbol);
  -webkit-mask: repeat left center/var(--starsize) var(--symbol);
}
.rating::-moz-range-thumb {
  height: var(--starsize);
  opacity: 0;
  width: var(--starsize);
}
.rating::-webkit-slider-thumb {
  height: var(--starsize);
  opacity: 0;
  width: var(--starsize);
  -webkit-appearance: none;
}

.moz-appearance-textfield {
  -moz-appearance: textfield;
}

/* ================================== */

.with-sidebar {
  grid-template-columns: 1fr;
  grid-auto-flow: dense;
}

@media screen and (min-width: 768px) {
  .with-sidebar {
    grid-template-columns: 1fr 23rem;
  }
}

/*  DEV helper classes ============================================ */

/* .\?\?\? {
  outline: 3px solid rgba(255, 0, 195, 0.237);
  outline-offset: -1px;
} */

/* .\?\?\?\? {
  outline: 2px dashed rgba(0, 229, 255, 0.345);
  outline-offset: -1px;
} */

/* .\?\?\?\?\? {
  outline: 2px dotted rgba(255, 251, 0, 0.237);
  outline-offset: -1px;
} */

/* .\?\?\?\?\?\? {
  outline: 2px dotted rgba(0, 255, 4, 0.237);
  outline-offset: -1px;
} */

/* ============================================================ */

/*  TipTap Classes
============================================ */
.tiptap.ProseMirror:focus {
  outline: none;
}
/* Headings */
.tiptap.ProseMirror h1 {
  font-size: 2em;
  font-weight: bold;
  margin: 0.67em 0;
}

.tiptap.ProseMirror h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.83em 0;
}

.tiptap.ProseMirror h3 {
  font-size: 1.17em;
  font-weight: bold;
  margin: 1em 0;
}

.tiptap.ProseMirror h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 1.33em 0;
}

.tiptap.ProseMirror h5 {
  font-size: 0.83em;
  font-weight: bold;
  margin: 1.67em 0;
}

.tiptap.ProseMirror h6 {
  font-size: 0.67em;
  font-weight: bold;
  margin: 2.33em 0;
}

/* Lists */
.tiptap.ProseMirror ul {
  list-style-type: disc;
  padding-left: 40px;
}

.tiptap.ProseMirror ol {
  list-style-type: decimal;
  padding-left: 40px;
}

.tiptap.ProseMirror li {
  margin-bottom: 0.5em;
}

/* Blockquote */
.tiptap.ProseMirror blockquote {
  margin: 1em 0;
  padding-left: 1em;
  border-left: 2px solid #ddd;
}

/* Code */
.tiptap.ProseMirror pre {
  padding: 1em;
  background: #f5f5f5;
  border-radius: 5px;
}

.tiptap.ProseMirror code {
  font-family: monospace;
}

/* Links */
.tiptap.ProseMirror a {
  color: #0366d6;
  text-decoration: none;
}

.tiptap.ProseMirror a:hover {
  text-decoration: underline;
}

/* Images */
.tiptap.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* ============================================================ */

.primary-gradient {
  background-image: linear-gradient(to right, #f77808, #f75808);
}

.fade-x {
  --jh-fade-x-margin: 2%;
  --jh-fade-x-padding: 25%;
  -webkit-mask-image: linear-gradient(
    to right,
    transparent var(--jh-fade-x-margin),
    black var(--jh-fade-x-padding),
    black calc(100% - var(--jh-fade-x-padding)),
    transparent calc(100% - var(--jh-fade-x-margin))
  );
  mask-image: linear-gradient(
    to right,
    transparent var(--jh-fade-x-margin),
    black var(--jh-fade-x-padding),
    black calc(100% - var(--jh-fade-x-padding)),
    transparent calc(100% - var(--jh-fade-x-margin))
  );
}

.fade-y {
  --jh-fade-x-margin: 2%;
  --jh-fade-x-padding: 25%;
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent var(--jh-fade-x-margin),
    black var(--jh-fade-x-padding),
    black calc(100% - var(--jh-fade-x-padding)),
    transparent calc(100% - var(--jh-fade-x-margin))
  );
  mask-image: linear-gradient(
    to bottom,
    transparent var(--jh-fade-x-margin),
    black var(--jh-fade-x-padding),
    black calc(100% - var(--jh-fade-x-padding)),
    transparent calc(100% - var(--jh-fade-x-margin))
  );
}

@media (min-width: 1300px) {
  .blog-section-fade-x {
    --container-size: 120rem;
    --fade-x-margin: 2%;
    /* --fade-x-padding: calc((100vw - 72rem) /2); */
    -webkit-mask-image: linear-gradient(
      to right,
      transparent var(--fade-x-margin),
      black calc((var(--container-size) - 72rem) / 2),
      black calc(var(--container-size) - (var(--container-size) - 72rem) / 2),
      transparent calc(100% - var(--fade-x-margin))
    );
    mask-image: linear-gradient(
      to right,
      transparent var(--fade-x-margin),
      black calc((var(--container-size) - 72rem) / 2),
      black calc(var(--container-size) - calc((var(--container-size) - 72rem) / 2)),
      transparent calc(100% - var(--fade-x-margin))
    );
  }
}

@media (min-width: 1300px) {
  .recommended-classes-section-fade-x {
    --jh-fade-x-margin: 2%;
    /* --jh-fade-x-padding: calc((100vw - 72rem) /2); */
    -webkit-mask-image: linear-gradient(to right, transparent 1%, black 6%, black 93%, transparent 97%);
    mask-image: linear-gradient(to right, transparent 1%, black 6%, black 93%, transparent 97%);
  }
}

.max-w-classes-like-this {
  max-width: calc(100vw - 4rem);
}

@media (min-width: 768px) {
  .max-w-classes-like-this {
    max-width: min(45rem + 4rem, 100vw - 2rem - 23rem + 1rem);
  }
}

/* HTML: <div class="triangle"></div> */
.triangle {
  --r: 0.2rem; /* border radius */

  width: 2rem;
  aspect-ratio: 1;
  border-top-right-radius: var(--r);
  clip-path: polygon(100% 100%, 0 0, 100% 0);
  --_g: calc(50% * (1 + 1 / tan(22.5deg)) - var(--r) / (3 * sqrt(2) - 4));
  --_r: calc(var(--r) / tan(22.5deg)), #000 98%, #0000 101%;
  -webkit-mask: radial-gradient(var(--r) at top var(--r) left var(--_r)),
    radial-gradient(var(--r) at right var(--r) bottom var(--_r)),
    conic-gradient(from 22.5deg at calc(100% - var(--_g)) var(--_g), #000 45deg, #0000 0);
}
